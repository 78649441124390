import Countdown from 'react-countdown';

// eslint-disable-next-line no-unused-vars
const countdownRender = ({ hours, minutes, completed }) =>
   completed ? (
      <span>ZROBIONA</span>
   ) : (
      <span>
         Pozostało {hours} godzin {minutes} minut
      </span>
   );

const WareHouseCountdown = ({ date }) => {
   return <Countdown renderer={countdownRender} date={date.add(3, 'h').toDate()} />;
};

export default WareHouseCountdown;
