import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { attribute, paletteTypes } from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const Palettes = ({ palettes }) => {
   const palettesRendered = palettes.map((p) => {
      const parts = p.orderParts.map((op) => {
         return (
            <tr key={op.id}>
               <td>
                  <ZamowieniaLink
                     displayId={op.order.zamowieniaId}
                     zamowieniaId={op.order.zamowieniaId}
                  />
               </td>
               <td>
                  <Products products={op.products} />;
               </td>
            </tr>
         );
      });
      return (
         <tr key={p.id}>
            <td>{p.group.companyName}</td>
            <td>{p.group.publicId}</td>
            <td>{p.publicId}</td>
            <td>{attribute('width', p.attributes)}</td>
            <td>{attribute('height', p.attributes)}</td>
            <td>{attribute('length', p.attributes)}</td>
            <td>{attribute('weight', p.attributes)}</td>
            <td>{paletteTypes[p.type]}</td>
            <td>{parts}</td>
            <td>{p.sentAt}</td>
         </tr>
      );
   });

   return (
      <Container>
         <table className="table table-hover">
            <caption>
               U góry tabeli znajdą się te wysłane przed momentem, a na dole te wysłane najwcześniej
               (np. z rana).
            </caption>
            <thead>
               <tr>
                  <th>Klient</th>
                  <th>Id grupy</th>
                  <th>Identyfikacja</th>
                  <th>Szerokość</th>
                  <th>Wysokość</th>
                  <th>Długość</th>
                  <th>Waga</th>
                  <th>Typ</th>
                  <th>Zamówienia / części zamówień</th>
                  <th>Wysłana</th>
               </tr>
            </thead>
            <tbody>{palettesRendered}</tbody>
         </table>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

export default Palettes;
