import { useFormikContext } from 'formik';
import { useContext } from 'react';

import { CanNotBePackedDueTo } from 'Shared/Warehouse/Warehouse';

import { Context } from '../../Context';

const PackBtn = ({ group }) => {
   const { refetchForPacking } = useContext(Context);
   const { submitForm } = useFormikContext();
   if (group.canNotBePackedDueTo && group.canNotBePackedDueTo !== '') {
      return <CanNotBePackedDueTo group={group} />;
   }
   const pack = async () => {
      submitForm();
      refetchForPacking();
   };

   return (
      <button className="btn btn-success" type="button" onClick={pack}>
         Spakuj
      </button>
   );
};

export default PackBtn;
