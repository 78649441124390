import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Context } from '../Context';

const createOptions = ({ groups, id, companyName }) =>
   groups
      .filter((g) => g.id !== id && g.companyName === companyName)
      .map((g) => {
         const ids = g.orderParts.map(({ order }) => order.zamowieniaId);
         return (
            <option key={g.id} value={g.id}>
               {g.publicId} | {g.companyName} | {ids.join(', ')}
            </option>
         );
      });

const Groups = ({ formik, options }) => {
   return (
      <div>
         <label htmlFor="groupId">
            Grupa
            <select
               className="form-control"
               id="groupId"
               name="groupId"
               onBlur={formik.handleBlur}
               onChange={formik.handleChange}
               value={formik.values.groupId}
            >
               <option value="" />
               {options}
            </select>
         </label>
         <Button className="btn btn-success" type="submit" onClick={formik.handleSubmit}>
            Przenieś
         </Button>
      </div>
   );
};

const MoveToAnotherGroup = ({ part, group }) => {
   const { refetch } = useContext(Context);
   const groupsReadyToTakeExtraOrders = useSelector(
      (s) => s.warehouse.orderGroupsReadyToTakeExtraOrders,
   );
   const [show, setShow] = useState(false);
   const formik = useFormik({
      initialValues: {
         partId: part.id,
         groupId: '',
      },
      onSubmit: (values) => {
         axios.post('/warehouse/move-part-to-another-group', values).then(() => {
            refetch();
         });
      },
   });

   if (!part.canBeMovedToAnotherGroup) return null;
   const options = groupsReadyToTakeExtraOrders
      ? createOptions({
           groups: groupsReadyToTakeExtraOrders,
           id: group.id,
           companyName: group.companyName,
        })
      : [];
   if (!options.length) return null;
   return (
      <form onSubmit={formik.handleSubmit}>
         <Button className="btn btn-success" type="button" onClick={() => setShow((s) => !s)}>
            Przenieś do innej grupy
         </Button>
         {show && (
            <Groups
               group={group}
               options={options}
               formik={formik}
               groups={groupsReadyToTakeExtraOrders}
            />
         )}
      </form>
   );
};

const Button = styled.button`
   width: 100%;
`;

export default MoveToAnotherGroup;
