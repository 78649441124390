import axios from 'axios';
import { useFormik } from 'formik';
import styled from 'styled-components';

const initialValues = {};

const cancel = (groupId) => axios.post(`/warehouse/groups/${groupId}/cancel`);

const CancelButton = ({ className, group, refetch }) => {
   const formik = useFormik({
      initialValues,
      onSubmit: () => {
         cancel(group.id).then(() => {
            refetch();
         });
      },
   });
   if (!group.canBeCancelled && !group.canBeCancelledUntil) return null;

   return (
      <div>
         <Button
            type="button"
            className={`btn btn-danger ${className}`}
            onClick={formik.submitForm}
            disabled={formik.isSubmitting}
         >
            Anuluj plan {group.canBeCancelledUntil && <>do {group.canBeCancelledUntil}</>}
         </Button>
      </div>
   );
};

const Button = styled.button`
   width: 100%;
`;

export default CancelButton;
