import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './app.css';
import './calendar.css';

import PrivateRoute from 'App/PrivateRoute';
import useKNSColors from 'deprecatedTheme/useKNSColors';
import DashboardWarehouse from 'Pages/Dashboards/Warehouse/DashboardWarehouse';
import FinishingGates from 'Pages/FinishingGates/FinishingGates';
import Footer from 'Pages/Footer/Footer';
import Header from 'Pages/Header/Header';
import Home from 'Pages/Home/Home';
import Login from 'Pages/Login/Login';
import PaintShop from 'Pages/PaintShop/PaintShop';
import ProductionOrderCheckIn from 'Pages/ProductionOrderCheckIn/ProductionOrderCheckIn';
import WarehouseForklift from 'Pages/WarehouseForklift/WarehouseForklift';
import WarehouseManager from 'Pages/WarehouseManager/WarehouseManager';
import WarehouseOffice from 'Pages/WarehouseOffice/WarehouseOffice';
import WarehousePacking from 'Pages/WarehousePacking/WarehousePacking';
import WarehousePackingIntoWarehouse from 'Pages/WarehousePackingIntoWarehouse/WarehousePackingIntoWarehouse';
import WarehousePalettes from 'Pages/WarehousePalettes/WarehousePalettes';
import WarehouseSending from 'Pages/WarehouseSending/WarehouseSending';

const App = () => {
   useKNSColors();

   return (
      <Router>
         <Header />
         <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/sign-in" element={<Login />} />
            <Route path="/dashboards/warehouse" element={<DashboardWarehouse />} />
            <Route
               path="/paint-shop"
               element={
                  <PrivateRoute
                     element={<PaintShop />}
                     accessOnlyForRoles={['ROLE_LAKIERNIA', 'ROLE_WIESZAKI']}
                  />
               }
            />
            <Route
               path="/warehouse-manager"
               element={
                  <PrivateRoute
                     element={<WarehouseManager />}
                     accessOnlyForWorkingPlaces={['WAREHOUSE_MANAGER', 'WAREHOUSE_LOADING_MANAGER']}
                  />
               }
            />

            <Route
               path="/warehouse-packing"
               element={
                  <PrivateRoute
                     element={<WarehousePacking />}
                     accessOnlyForRoles={['ROLE_PACKING']}
                  />
               }
            />
            <Route
               path="/warehouse-packing-into-warehouse"
               element={
                  <PrivateRoute
                     element={<WarehousePackingIntoWarehouse />}
                     accessOnlyForRoles={['ROLE_PACKING_INTO_WAREHOUSE']}
                  />
               }
            />
            <Route
               path="/warehouse-forklift"
               element={
                  <PrivateRoute
                     element={<WarehouseForklift />}
                     accessOnlyForRoles={['ROLE_WAREHOUSE_FORKLIFT']}
                     accessOnlyForWorkingPlaces={['WAREHOUSE_LOADING_MANAGER']}
                  />
               }
            />
            <Route
               path="/warehouse-office"
               element={
                  <PrivateRoute
                     element={<WarehouseOffice />}
                     accessOnlyForRoles={['ROLE_MAGAZYN_BIURO']}
                  />
               }
            />
            <Route
               path="/warehouse-palettes"
               element={
                  <PrivateRoute
                     element={<WarehousePalettes />}
                     accessOnlyForRoles={['ROLE_WAREHOUSE_FORKLIFT', 'ROLE_MAGAZYN_BIURO']}
                     accessOnlyForWorkingPlaces={['WAREHOUSE_MANAGER', 'WAREHOUSE_LOADING_MANAGER']}
                  />
               }
            />
            <Route
               path="/production-order-check-in"
               element={
                  <PrivateRoute
                     element={<ProductionOrderCheckIn />}
                     accessOnlyForRoles={['ROLE_PRODUCTION']}
                  />
               }
            />
            <Route
               path="/warehouse-sending"
               element={
                  <PrivateRoute
                     element={<WarehouseSending />}
                     accessOnlyForRoles={['ROLE_WAREHOUSE_SENDING']}
                     accessOnlyForWorkingPlaces={['WAREHOUSE_LOADING_MANAGER']}
                  />
               }
            />
            <Route
               path="/finishing-gates"
               element={
                  <PrivateRoute
                     element={<FinishingGates />}
                     accessOnlyForRoles={['ROLE_FINISHING_GATES']}
                  />
               }
            />
         </Routes>
         <Footer />
      </Router>
   );
};

export default App;
