import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const findPostinProduct = (product) => {
   const attributes = product.originalProduct.rawArray;
   return (
      ['1', '2'].includes(attributes?.slupy_na_markach) ||
      ['1', '2'].includes(attributes?.slupy_do_wbetonowania) ||
      attributes?.slupy === 'STANDARDOWE'
   );
};

const getPalettesWithPosts = (palettes) => {
   return palettes
      .map((palette) => ({
         ...palette,
         orderParts: palette.orderParts
            .map((orderPart) => ({
               ...orderPart,
               products: orderPart.products.filter((product) => findPostinProduct(product)),
            }))
            .filter((orderPart) => orderPart.products.length > 0),
      }))
      .filter((palette) => palette.orderParts.length > 0);
};

const PostExtraLabels = ({ palettes, groupId }) => {
   const currentTime = new Date();
   const [palettesWithPosts, setPalettesWithPosts] = useState(null);

   useEffect(() => {
      setPalettesWithPosts(getPalettesWithPosts(palettes));
   }, []);

   return (
      palettesWithPosts !== null &&
      palettesWithPosts.length > 0 && (
         <div>
            {palettesWithPosts.map((palette) => {
               return palette.orderParts.map((op) => {
                  return op.products.map((p) => {
                     return (
                        <LabelPrintable key={p.originalProduct.id}>
                           <LabelContent>
                              <LogoContainer>
                                 <Logo src="vectors/konsport_logo_primary.svg" alt="" />
                              </LogoContainer>
                              <div>
                                 <div>
                                    <b>Klient: </b>
                                    {palette.orderParts[0].order.companyName}
                                 </div>
                                 <div>
                                    <b>Id grupy: </b>
                                    {groupId}
                                 </div>
                                 <div>
                                    <b>Część: </b>
                                    {palette.publicId}
                                 </div>
                                 <Section>
                                    <b>Spakowano: </b>
                                    {dayjs(currentTime).format('HH:mm  DD-MM-YYYY')}
                                 </Section>
                              </div>
                              <ProductsContainer>
                                 <hr />
                                 <div>
                                    <Section>
                                       Słupy do {p.originalProduct.rawArray.typ}
                                       {p.originalProduct.rawArray.model} z palety {op.publicId}
                                    </Section>
                                 </div>
                              </ProductsContainer>
                           </LabelContent>
                           <Footnote>
                              Etykieta wygenerowana automatycznie dla słupów zamawianych w zestawie
                              z bramą lub furtką.
                           </Footnote>
                        </LabelPrintable>
                     );
                  });
               });
            })}
         </div>
      )
   );
};

const Logo = styled.img`
   @media print {
      height: 40px;
   }
`;

const LogoContainer = styled.div`
   display: none;
   margin-bottom: 20px;

   @media print {
      display: inherit;
      margin-bottom: 30px;
   }
`;

const Footnote = styled.div`
   display: none;

   @media print {
      display: inherit;
      align-self: flex-end;
      font-size: 0.8rem;
      font-style: italic;
   }
`;

const LabelContent = styled.div`
   flex-grow: 1;
`;

const ProductsContainer = styled.div`
   margin-top: 1rem;
`;

const Section = styled.div`
   font-style: italic;
   font-size: 1.2rem;
`;

const LabelPrintable = styled.div`
   page-break-after: always;
   display: flex;
   flex-direction: column;

   @media print {
      @page {
      size: A5;  
      }
   }
      padding: 20px;
      width: 148mm; 
      height: 210mm;
      font-size: 2rem;
      padding: 40px;
   }

   @media screen {
      padding: 10px;
      height: auto;
      width: auto;
   }
`;

export default PostExtraLabels;
