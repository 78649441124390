import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';

import { Context } from '../../Context';

const initialValues = {
   color: '',
   otherColor: '',
};

const createValidationSchema = (orderColors) =>
   yup.object().shape({
      color: yup.string().notOneOf(orderColors),
      otherColor: yup.string().notOneOf(orderColors).min(3),
   });

const Add = ({ order, orderColors }) => {
   const { refetch } = useContext(Context);
   const availableColors = useSelector((s) => s.app.colors.all);
   const formik = useFormik({
      initialValues,
      validationSchema: createValidationSchema(orderColors),
      onSubmit: (values, { resetForm }) => {
         const c = values.otherColor || values.color;
         if (orderColors.includes(c)) {
            return;
         }
         const color = [...orderColors, c];
         axios
            .put(`/paint-shop/orders/${order.id}/assign-color`, {
               color,
            })
            .then(() => {
               refetch();
               resetForm();
            });
      },
   });
   const colorsRendered = availableColors.map((c) => (
      <option key={c.color} value={c.color}>
         {c.color}
      </option>
   ));
   const disabled = formik.isSubmitting || Object.keys(formik.errors).length;

   return (
      <Container onSubmit={formik.handleSubmit}>
         <div>
            <label htmlFor="id">
               Kolor
               <select
                  id="id"
                  className="form-control"
                  name="color"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.color}
               >
                  <option value="" />
                  {colorsRendered}
                  <option value="COLOR_FILLED_MANUALLY">Inny kolor</option>
               </select>
            </label>
         </div>
         {formik.values.color === 'COLOR_FILLED_MANUALLY' && (
            <div>
               <label htmlFor="otherColor">
                  Inny kolor
                  <input
                     type="text"
                     className="form-control"
                     id="otherColor"
                     name="otherColor"
                     value={formik.values.otherColor}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
               </label>
            </div>
         )}
         <div>
            <AddBtn className="btn btn-success" type="submit" disabled={disabled}>
               Dodaj
            </AddBtn>
         </div>
      </Container>
   );
};

const Container = styled.form`
   display: flex;
   flex-direction: column;
   position: relative;
`;

const AddBtn = styled.button`
   margin-top: 1rem;
`;

export default Add;
