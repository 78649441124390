export const TYPE_CONSTRUCTION_FOR_GOODS = 'CONSTRUCTION_FOR_GOODS';
export const TYPE_CONSTRUCTION_FOR_POLES_SMALL = 'TYPE_CONSTRUCTION_FOR_POLES_SMALL';
export const TYPE_CONSTRUCTION_FOR_POLES_BIG = 'TYPE_CONSTRUCTION_FOR_POLES_BIG';
export const TYPE_DEPOSIT_FREE = 'DEPOSIT_FREE';
export const TYPE_BOX = 'BOX';
export const TYPE_EQ_POLE = 'EQ_POLE';
export const TYPE_GATE_SELF_SUPPORTING = 'GATE_SELF_SUPPORTING';
export const TYPE_GATE_SELF_SUPPORTING_LONG = 'GATE_SELF_SUPPORTING_MULTI_ELEMENT';
export const TYPE_ACCESSORIES = 'ACCESSORIES';
export const TYPE_CONCRETE = 'CONCRETE';
export const TYPE_CARPORT = 'CARPORT';
// It's regarding galvanized poles (actually Galfan shell/coat)
export const TYPE_GALFAN = 'GALFAN';
export const TYPE_GALVANIZED = 'GALVANIZED';
// Towar luzem
export const TYPE_LOOSE_GOODS = 'LOOSE_GOODS';
// Panele z placu
export const TYPE_PANELS_FROM_SQUARE = 'PANELS_FROM_SQUARE';
// Euro Konsport
// To store palettes with poles one on top of the other
export const TYPE_EURO_KONSPORT = 'EURO_KONSPORT';

export const paletteTypes = {
   [TYPE_CONSTRUCTION_FOR_GOODS]: 'Ramiak na towar',
   [TYPE_CONSTRUCTION_FOR_POLES_SMALL]: 'Ramiak na słupki wąskie',
   [TYPE_CONSTRUCTION_FOR_POLES_BIG]: 'Ramiak na słupki szerokie',
   [TYPE_DEPOSIT_FREE]: 'Paleta niekaucyjna',
   [TYPE_BOX]: 'Karton',
   [TYPE_EQ_POLE]: 'Słupek EQ',
   [TYPE_GATE_SELF_SUPPORTING]: 'Brama samonośna',
   [TYPE_GATE_SELF_SUPPORTING_LONG]: 'Brama samonośna skręcana',
   [TYPE_ACCESSORIES]: 'Akcesoria',
   [TYPE_CONCRETE]: 'Beton',
   [TYPE_CARPORT]: 'Carport',
   // It's regarding galvanized poles (actually Galfan shell/coat)
   [TYPE_GALFAN]: 'Galfan',
   [TYPE_GALVANIZED]: 'Ocynk',
   // Towar luzem
   [TYPE_LOOSE_GOODS]: 'Towar luzem',
   // Panele z placu
   [TYPE_PANELS_FROM_SQUARE]: 'Panele z placu',
   // Euro Konsport
   [TYPE_EURO_KONSPORT]: 'Euro Konsport',
};

export const PaletteTypesOptions = () => (
   <>
      <option value="">Typ</option>
      <option key={TYPE_CONSTRUCTION_FOR_GOODS} value={TYPE_CONSTRUCTION_FOR_GOODS}>
         {paletteTypes[TYPE_CONSTRUCTION_FOR_GOODS]}
      </option>
      <option key={TYPE_CONSTRUCTION_FOR_POLES_SMALL} value={TYPE_CONSTRUCTION_FOR_POLES_SMALL}>
         {paletteTypes[TYPE_CONSTRUCTION_FOR_POLES_SMALL]}
      </option>
      <option key={TYPE_CONSTRUCTION_FOR_POLES_BIG} value={TYPE_CONSTRUCTION_FOR_POLES_BIG}>
         {paletteTypes[TYPE_CONSTRUCTION_FOR_POLES_BIG]}
      </option>
      <option key={TYPE_DEPOSIT_FREE} value={TYPE_DEPOSIT_FREE}>
         {paletteTypes[TYPE_DEPOSIT_FREE]}
      </option>
      <option key={TYPE_BOX} value={TYPE_BOX}>
         {paletteTypes[TYPE_BOX]}
      </option>
      <option key={TYPE_EQ_POLE} value={TYPE_EQ_POLE}>
         {paletteTypes[TYPE_EQ_POLE]}
      </option>
      <option key={TYPE_GATE_SELF_SUPPORTING} value={TYPE_GATE_SELF_SUPPORTING}>
         {paletteTypes[TYPE_GATE_SELF_SUPPORTING]}
      </option>
      <option key={TYPE_GATE_SELF_SUPPORTING_LONG} value={TYPE_GATE_SELF_SUPPORTING_LONG}>
         {paletteTypes[TYPE_GATE_SELF_SUPPORTING_LONG]}
      </option>
      <option key={TYPE_ACCESSORIES} value={TYPE_ACCESSORIES}>
         {paletteTypes[TYPE_ACCESSORIES]}
      </option>
      <option key={TYPE_CONCRETE} value={TYPE_CONCRETE}>
         {paletteTypes[TYPE_CONCRETE]}
      </option>
      <option key={TYPE_CARPORT} value={TYPE_CARPORT}>
         {paletteTypes[TYPE_CARPORT]}
      </option>
      <option key={TYPE_GALFAN} value={TYPE_GALFAN}>
         {paletteTypes[TYPE_GALFAN]}
      </option>
      <option key={TYPE_GALVANIZED} value={TYPE_GALVANIZED}>
         {paletteTypes[TYPE_GALVANIZED]}
      </option>
      <option key={TYPE_LOOSE_GOODS} value={TYPE_LOOSE_GOODS}>
         {paletteTypes[TYPE_LOOSE_GOODS]}
      </option>
      <option key={TYPE_PANELS_FROM_SQUARE} value={TYPE_PANELS_FROM_SQUARE}>
         {paletteTypes[TYPE_PANELS_FROM_SQUARE]}
      </option>
      <option key={TYPE_EURO_KONSPORT} value={TYPE_EURO_KONSPORT}>
         {paletteTypes[TYPE_EURO_KONSPORT]}
      </option>
   </>
);

export const attribute = (key, attributes) => {
   const attr = attributes.find((a) => a.key === key);
   return attr ? attr.value : '';
};
