import { combineReducers } from 'redux';

import app from './app';
import order from './order';
import warehouse from './warehouse';

const reducers = combineReducers({
   app,
   order,
   warehouse,
});

export default reducers;
