import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { doesUserHasRole, loadUser, signOut } from 'auth';

const LogoutBtn = styled.button`
   &:hover {
      color: #ffffff;
   }

   &:focus {
      border: none;
      outline: none;
      box-shadow: none;
   }
`;

const NavContainer = styled.nav`
   display: flex;
   justify-content: flex-end;

   & > .nav-item {
      &:not(:first-child) {
         margin-left: 1rem;
      }

      & > .nav-link {
         color: #cacbcc;
         border: 1px transparent;

         &.active {
            background: #ffffff;
            color: #000000;
            border-radius: 22px;
         }
      }

      & > .btn {
         color: #cacbcc;
      }
   }
`;

const Nav = () => {
   const location = useLocation();
   const user = loadUser();
   const links = [];
   const clickLogout = () => {
      signOut();
   };
   const admin = doesUserHasRole('ROLE_ADMIN', user?.roles);
   const paintShop = doesUserHasRole('ROLE_LAKIERNIA', user?.roles);
   const warehousePacking = doesUserHasRole('ROLE_KOMPLETOWANIE_PALET', user?.roles);
   const warehouseOffice = doesUserHasRole('ROLE_MAGAZYN_BIURO', user?.roles);
   const warehouseForklift = doesUserHasRole('ROLE_WAREHOUSE_FORKLIFT', user?.roles);
   const finishingGates = doesUserHasRole('ROLE_FINISHING_GATES', user?.roles);
   const warehouseManager = user?.workingPlace === 'WAREHOUSE_MANAGER';
   const warehouseLoadingManager = user?.workingPlace === 'WAREHOUSE_LOADING_MANAGER';

   if (user) {
      if (paintShop) {
         const classPaintShop = location.pathname === '/paint-shop' ? 'active' : '';
         links.push(
            <li key="/paint-shop" className="nav-item">
               <Link to="/paint-shop" className={`nav-link ${classPaintShop}`}>
                  Lakiernia
               </Link>
            </li>,
         );
      }

      if (warehouseManager) {
         const classWarehouseManager = location.pathname === '/warehouse-manager' ? 'active' : '';
         links.push(
            <li key="/warehouse-manager" className="nav-item">
               <Link to="/warehouse-manager" className={`nav-link ${classWarehouseManager}`}>
                  Magazyn
               </Link>
            </li>,
         );
      }

      if (warehousePacking) {
         const classWarehouseManager = location.pathname === '/warehouse-packing' ? 'active' : '';
         links.push(
            <li key="/warehouse-packing" className="nav-item">
               <Link to="/warehouse-packing" className={`nav-link ${classWarehouseManager}`}>
                  Magazyn
               </Link>
            </li>,
         );
      }

      if (warehouseManager || warehouseForklift || warehouseOffice || warehouseLoadingManager) {
         const classWarehousePalettes = location.pathname === '/warehouse-palettes' ? 'active' : '';
         links.push(
            <li key="/warehouse-palettes" className="nav-item">
               <Link to="/warehouse-palettes" className={`nav-link ${classWarehousePalettes}`}>
                  Strefa
               </Link>
            </li>,
         );
      }

      if (warehouseManager) {
         const classWarehousePalettes = location.pathname === '/warehouse-tools' ? 'active' : '';
         links.push(
            <li key="/warehouse-tools" className="nav-item">
               <Link to="/warehouse-tools" className={`nav-link ${classWarehousePalettes}`}>
                  Narzędzia
               </Link>
            </li>,
         );
      }

      if (warehouseOffice) {
         const classWarehouseOffice = location.pathname === '/warehouse-office' ? 'active' : '';
         links.push(
            <li key="/warehouse-office" className="nav-item">
               <Link to="/warehouse-office" className={`nav-link ${classWarehouseOffice}`}>
                  Magazyn
               </Link>
            </li>,
         );
      }

      if (finishingGates) {
         const classfinishingGates = location.pathname === '/finishing-gates' ? 'active' : '';
         links.push(
            <li key="/finishing-gates" className="nav-item">
               <Link to="/finishing-gates" className={`nav-link ${classfinishingGates}`}>
                  Zbrojenie bram
               </Link>
            </li>,
         );
      }

      if (admin) {
         const classUsers = location.pathname === '/users' ? 'active' : '';
         links.push(
            <li key="/users" className="nav-item">
               <Link to="/users" className={`nav-link ${classUsers}`}>
                  Użytkownicy
               </Link>
            </li>,
         );
      }

      if (warehouseLoadingManager) {
         const classfinishingGates = location.pathname === '/warehouse-forklift' ? 'active' : '';
         links.push(
            <li key="/warehouse-forklift" className="nav-item">
               <Link to="/warehouse-forklift" className={`nav-link ${classfinishingGates}`}>
                  Wózek widłowy
               </Link>
            </li>,
         );
      }

      if (warehouseLoadingManager) {
         const classfinishingGates = location.pathname === '/warehouse-sending' ? 'active' : '';
         links.push(
            <li key="/warehouse-sending" className="nav-item">
               <Link to="/warehouse-sending" className={`nav-link ${classfinishingGates}`}>
                  Wysyłanie
               </Link>
            </li>,
         );
      }

      const pathProfile = `/users/${user.id}`;
      const classProfile = location.pathname.includes('users/') ? 'active' : '';
      links.push(
         <li key={pathProfile} className="nav-item">
            <Link to={pathProfile} className={`nav-link ${classProfile}`}>
               Panel klienta
            </Link>
         </li>,
      );

      links.push(
         <li key="logout" className="nav-item">
            <LogoutBtn className="nav-link btn" onClick={clickLogout}>
               Wyloguj
            </LogoutBtn>
         </li>,
      );
   }

   return (
      <div>
         <NavContainer className="nav nav-pills">{links}</NavContainer>
      </div>
   );
};

export default Nav;
