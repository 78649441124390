import axios from 'axios';
import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { loadUser } from 'auth';
import InternalContainer from 'Layouts/InternalContainer';
import CheckedInFilters from 'Shared/CheckedInFilters/CheckedInFilters';
import defaults from 'Shared/useQuery';
import UseQueryStates from 'Shared/UseQueryStates';
import OpenZamowieniaPdfForm from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { filterChanged } from 'state/warehouse';
import { Loader } from 'ui/Loader';

import Form from './Form';
import Orders from './Orders/Orders';

const today = new Date();

const guessWorkingPlaceLabel = (workingPlace) => {
   switch (workingPlace) {
      case 'POTS_AND_BLOCKS_PRODUCTION':
         return 'Kazimierz';
      case 'SEMI_FINISHED_PRODUCTS_WAREHOUSE':
      case 'SEMI-FINISHED-PRODUCTS-RUBBERING':
      case 'SEMI-FINISHED-PRODUCTS-METAL-ROLLING':
      case 'SEMI-FINISHED-PRODUCTS-LASER':
         return 'Bufor';
      case 'SEMI_FINISHED_PRODUCTS_PRODUCTION':
         return 'Galanteria';
      case 'WAREHOUSE-EQUIPMENT':
         return 'Magazyn, zaopatrzenie';
      case 'WAREHOUSE-WRAPPING':
      case 'WAREHOUSE-LOADING':
         return 'Magazyn';
      case 'WAREHOUSE-WELDING':
         return 'Spawalnia';
      default:
         return '';
   }
};

const fetch = (filters) => {
   return axios.get('/production/orders', {
      params: {
         checked_in_at: filters.importedAt ? dayjs(filters.importedAt).format('YYYY-MM-DD') : null,
         checked_in_from: filters.importedAtRange?.from
            ? dayjs(filters.importedAtRange?.from).format('YYYY-MM-DD')
            : null,
         checked_in_to: filters.importedAtRange?.to
            ? dayjs(filters.importedAtRange?.to).format('YYYY-MM-DD')
            : null,
         zamowienia_id: filters.zamowieniaId,
      },
   });
};

const ProductionOrderCheckIn = () => {
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [workingPlace, setWorkingPlace] = useState('');
   const filters = useSelector((state) => state.warehouse.filters);
   const user = loadUser();
   const enabled = !!(
      filters.importedAt ||
      (filters.importedAtRange?.from && filters.importedAtRange?.to) ||
      filters.zamowieniaId
   );
   useEffect(() => {
      dispatch(filterChanged('importedAt', today));
   }, [dispatch]);
   const { refetch, isLoading, dataUpdatedAt, error } = useQuery(
      ['fetchOrders', { filters }],
      () => fetch(filters),
      {
         ...defaults,
         enabled,
         onSuccess: ({ data }) => {
            dispatch({
               type: 'FETCH_PRODUCTION_ORDERS',
               orders: data.orders,
            });
         },
      },
   );
   const userId = user.id;
   useEffect(() => {
      if (workingPlace || !userId) return;
      axios.get(`/users/${userId}`).then(({ data }) => {
         setWorkingPlace(data.user.workingPlace);
      });
   }, [workingPlace, userId]);
   const workingPlaceLabel = guessWorkingPlaceLabel(workingPlace);
   useEffect(() => {
      if (!enabled) return;
      refetch();
   }, [dispatch, filters]);

   return (
      <>
         <Helmet>
            <title>{workingPlaceLabel} | Konsport</title>
         </Helmet>
         <InternalContainer>
            <div className="row">
               <div className="col">
                  <div>
                     <h1>{workingPlaceLabel}</h1>
                  </div>
                  <ActionsContainer>
                     <FormsContainer>
                        <Form refetch={refetch} loading={loading} setLoading={setLoading} />
                        <OpenZamowieniaPdfForm />
                     </FormsContainer>
                     <CheckedInFilters />
                  </ActionsContainer>
                  <Orders refetch={refetch} />
                  {(isLoading || loading) && <Loader />}
                  <UseQueryStates error={error} updatedAt={dataUpdatedAt} isLoading={isLoading} />
               </div>
            </div>
         </InternalContainer>
      </>
   );
};

const ActionsContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

const FormsContainer = styled.div`
   > form:not(:last-child) {
      margin-bottom: 2rem;
   }
`;

export default ProductionOrderCheckIn;
