import { useContext } from 'react';

import { CanNotBePackedDueTo } from 'Shared/Warehouse/Warehouse';

import { Context } from '../../Context';

const PackedButton = ({ group }) => {
   const { setGroupForFinalPaletteModal } = useContext(Context);
   if (group.canNotBePackedDueTo && group.canNotBePackedDueTo !== '') {
      return <CanNotBePackedDueTo group={group} />;
   }

   const handleClick = () => {
      setGroupForFinalPaletteModal(group);
   };

   return (
      <button type="button" className="btn btn-success" onClick={() => handleClick()}>
         Spakuj na gotowo
      </button>
   );
};

export default PackedButton;
