import dayjs from 'dayjs';
import { useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import Products from 'Shared/Warehouse/Products';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import CancelButton from '../../../Shared/Warehouse/CancelButton';
import { Context } from '../Context';
import WareHouseCountdown from '../WareHouseCountdown';

import MoveToAnotherGroup from './MoveToAnotherGroup';
import PackButton from './PackButton';
import PackIntoWarehouseButton from './PackIntoWarehouseButton';
import Palette from './Palette';

const GroupWithoutFinalPalettes = ({ group }) => {
   const { refetchWarehouseGroups } = useContext(Context);
   const dispatch = useDispatch();
   const toScrollId = useSelector((s) => s.warehouse.orderPartsGroupIdToScroll);
   const toScrollRef = useRef(null);
   useEffect(() => {
      if (!toScrollRef.current || !toScrollId) return;
      toScrollRef.current.scrollIntoView(true);
      dispatch({ type: 'SCREEN_WAS_SCROLLED_TO_ORDER_PARTS_GROUP' });
   }, [toScrollRef.current, toScrollId]);
   const parts = group.parts.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <ZamowieniaLink
                  displayId={p.order.zamowieniaId}
                  zamowieniaId={p.order.zamowieniaId}
               />
            </td>

            <td>
               {p.order.startedPaintingAt && (
                  <WareHouseCountdown date={dayjs(p.order.startedPaintingAt)} />
               )}
            </td>
            <td>
               <Products products={p.products} />
            </td>
            <td>
               <Palette palette={p.palette} />
            </td>
            <td>
               <MoveToAnotherGroup part={p} group={group} />
            </td>
         </tr>
      );
   });
   const { createdByExternalClient } = group.parts[0].order;
   const ref = group.id === toScrollId ? toScrollRef : null;

   return (
      <tr key={group.id} ref={ref}>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={createdByExternalClient}
            />
         </td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th>Zamówienie / część zamówienia</th>
                     <th>Lakiernia</th>
                     <th>Produkty</th>
                     <th>Palety na strefie</th>
                     <th />
                  </tr>
               </thead>
               <tbody>{parts}</tbody>
            </table>
         </td>
         <td>
            <Actions>
               <PackButton group={group} />
               <PackIntoWarehouseButton group={group} />
               <CancelButton group={group} refetch={refetchWarehouseGroups} />
            </Actions>
         </td>
      </tr>
   );
};

const Actions = styled.div`
   display: flex;
   flex-direction: column;
`;

export default GroupWithoutFinalPalettes;
