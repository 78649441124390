import axios from 'axios';
import { useContext } from 'react';

import { CanNotBePackedDueTo } from 'Shared/Warehouse/Warehouse';

import { Context } from '../../Context';

const PackBtn = ({ orderPart, group }) => {
   const { refetchForPacking } = useContext(Context);
   const pack = async () => {
      await axios.post(
         `/warehouse/order-groups/${group.id}/order-parts/${orderPart.id}/pack-palette`,
      );
      refetchForPacking();
   };
   if (group.canNotBePackedDueTo && group.canNotBePackedDueTo !== '') {
      return <CanNotBePackedDueTo group={group} />;
   }

   return (
      <button className="btn btn-success" type="button" onClick={pack}>
         Spakuj
      </button>
   );
};

export default PackBtn;
