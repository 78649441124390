import { useContext } from 'react';

import CancelButton from 'Shared/Warehouse/CancelButton';
import { packingPlaces } from 'Shared/Warehouse/OrderPartsGroup';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import { Context } from './Context';
import FinalPalette from './FinalPalette';
import PlanPalettesAgainButton from './PlanPalettesAgainButton';
import ToBeSentButton from './ToBeSentButton';

const Group = ({ group }) => {
   const { refetch } = useContext(Context);
   const palettes = group.finalPalettes.map((p) => <FinalPalette key={p.id} finalPalette={p} />);

   return (
      <tr>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={group.createdByExternalClient}
            />
         </td>
         <td>{packingPlaces[group.packingPlace]}</td>
         <td>{group.packedAt}</td>
         <td>{palettes}</td>
         <td>
            <CancelButton group={group} refetch={refetch} />
            <PlanPalettesAgainButton group={group} />
            <ToBeSentButton group={group} />
         </td>
      </tr>
   );
};

export default Group;
