import { Navigate } from 'react-router-dom';

import { loadUser, signOut } from 'auth';

const isAuthorised = (roles, accessOnlyForRoles, workingPlace, accessOnlyForWorkingPlaces) => {
   if (accessOnlyForWorkingPlaces.length && workingPlace !== '') {
      return accessOnlyForWorkingPlaces.includes(workingPlace);
   }
   if (!accessOnlyForRoles.length) {
      return true;
   }
   return roles.some((r) => accessOnlyForRoles.includes(r));
};

// accessOnlyForWorkingPlaces is a new concept where we manage access more by where who works rather than by a user role.
// A user will mostly have a production role in this case.
const PrivateRoute = ({ element, accessOnlyForRoles, accessOnlyForWorkingPlaces = [] }) => {
   const user = loadUser();
   if (!user) {
      signOut();
      return <Navigate to={{ pathname: '/sign-in' }} />;
   }
   const authorised = isAuthorised(
      user.roles,
      accessOnlyForRoles,
      user.workingPlace || '',
      accessOnlyForWorkingPlaces,
   );

   if (!authorised) {
      signOut();
      return <Navigate to={{ pathname: '/sign-in' }} />;
   }

   return element;
};

export default PrivateRoute;
