import { useContext } from 'react';

import { CanNotBePackedDueTo } from 'Shared/Warehouse/Warehouse';

import { Context } from '../../Context';

// eslint-disable-next-line consistent-return
const PackedButton = ({ group }) => {
   const { setGroupForFinalPaletteModal } = useContext(Context);
   if (group.canNotBePackedDueTo && group.canNotBePackedDueTo !== '') {
      return <CanNotBePackedDueTo group={group} />;
   }
   return (
      <button
         type="button"
         className="btn btn-success"
         onClick={() => setGroupForFinalPaletteModal(group)}
      >
         Spakuj na gotowo
      </button>
   );
};

export default PackedButton;
