import axios from 'axios';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { removeAttribute } from 'Shared/PaintShop/order';
import { ORDER_ATTRIBUTE_COLOR } from 'Shared/Warehouse/OrderPart';

import { Context } from '../../Context';

import Add from './Add';

const findOrderColors = (attributes) => {
   const colors = attributes.find((a) => a.key === ORDER_ATTRIBUTE_COLOR);
   if (!colors) {
      return [];
   }
   if (typeof colors.value === 'string') {
      return JSON.parse(colors.value);
   }
   return [];
};

const Colors = ({ order }) => {
   const { manager, refetch } = useContext(Context);
   const availableColors = useSelector((s) => s.app.colors.all);
   if (!availableColors.length) {
      return null;
   }
   const orderColors = findOrderColors(order.attributes);
   if (manager) {
      const rendered = orderColors.map((c) => <li key={c}>{c}</li>);
      return (
         <div>
            <div>
               <span>Kolory</span>
            </div>
            <ul>{rendered}</ul>
         </div>
      );
   }
   const remove = (c) => {
      const color = removeAttribute(orderColors, c);
      axios
         .put(`/paint-shop/orders/${order.id}/assign-color`, {
            color,
         })
         .then(refetch);
   };
   // eslint-disable-next-line no-console
   console.log(typeof orderColors, orderColors);
   const rendered = orderColors.map((c) => (
      <li key={c}>
         <span>{c}</span>
         <button type="button" className="btn btn-link" onClick={() => remove(c)}>
            (usuń)
         </button>
      </li>
   ));

   return (
      <div>
         <div>
            <SavedColors>{rendered}</SavedColors>
         </div>
         <Add order={order} orderColors={orderColors} />
      </div>
   );
};

const SavedColors = styled.ul`
   padding-left: 1.5rem;
   font-size: 0.9rem;

   button {
      padding: 0;
      font-size: 1rem;
   }
`;

export default Colors;
