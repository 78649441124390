import styled from 'styled-components';

import ProductAmount from './ProductAmount';
import ProductPartSelect from './ProductPartSelect';

const Container = styled.div`
   display: flex;
   align-items: baseline;
   justify-content: space-between;
   & > div:not(:last-child) {
      margin-right: 1rem;
   }
   &:hover {
      background-color: #f8f9fa;
   }
`;

const NameContainer = styled.div`
   flex: 1;
   align-self: flex-end;
`;

const Product = ({ product, partsIndex, part, partsProductIndex }) => {
   const extraInfo =
      product.originalProduct.rawArray?.model || product.originalProduct.rawArray?.akcesoria2;

   return (
      <Container>
         <ProductAmount
            product={product}
            part={part}
            partsProductIndex={partsProductIndex}
            partsIndex={partsIndex}
         />
         <ProductPartSelect
            product={product}
            part={part}
            partsProductIndex={partsProductIndex}
            partsIndex={partsIndex}
         />
         <NameContainer>
            <div> {product.name} </div>
            {extraInfo && <div> {extraInfo} </div>}
         </NameContainer>
      </Container>
   );
};

export default Product;
